import { defineStore } from "pinia";
import { useSnackBarStore } from "@/stores/snackbar";
import axios from "@/plugins/axios";

export const useTestClockStore = defineStore("testClock", {
  state: () => ({
    loading: false,
  }),
  actions: {
    async advanceTime(testClockId: any, payload: any) {
      try {
        this.loading = true;
        const res = await axios.post(`/testClock/${testClockId}`, payload);
        return res.data;
      } catch (error: any) {
        useSnackBarStore().toastError(error.data);
      } finally {
        this.loading = false;
      }
    },

    async retrieve(testClockId: any) {
      try {
        this.loading = true;
        const res = await axios.get(`/testClock/${testClockId}`);
        return res.data;
      } catch (error: any) {
        useSnackBarStore().toastError(error.data);
      } finally {
        this.loading = false;
      }
    },
  },
});
