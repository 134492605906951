<template>
  <v-navigation-drawer
    v-model="useCartStore().isVisible"
    app
    location="right"
    width="420"
    temporary
  >
    <v-toolbar density="compact" color="primary" dark>
      <v-toolbar-title>{{ $t("common.cart.cart") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn 
        v-if="cartItems.length > 0" 
        icon="mdi-delete-sweep" 
        variant="text" 
        @click="useCartStore().resetCart()"
        title="Clear Cart"
      ></v-btn>
    </v-toolbar>

    <v-list v-if="cartItems?.length > 0" class="pt-0 cart-list">
      <transition-group name="slide-fade">
        
        <v-list-item
          v-for="(item, index) in cartItems"
          :key="index"
          class="py-1"
          data-cy="cart-item"
          density="compact"
        >
          <v-divider v-if="index > 0" class="mb-2" />
          <template v-slot:prepend>
            <div class="d-flex align-center">
              <v-btn
                icon="mdi-close"
                density="compact"
                size="x-small"
                color="error"
                variant="text"
                class="remove-btn mr-1"
                @click="useCartStore().removeItem(item.id)"
                title="Remove from cart"
              />
              <v-avatar size="40">
                <v-img
                  :src="getItemImage(item)"
                  cover
                  class="rounded"
                />
              </v-avatar>
            </div>
          </template>

          <!-- Main Content Section - Complete Redesign -->
          <div class="cart-item-content">
            <!-- Row 1: Product Name and Total Price -->
            <div class="d-flex justify-space-between align-center">
              <span class="text-subtitle-2 font-weight-medium text-truncate product-name">{{ getProductName(item.productId) }}</span>
              <span class="text-subtitle-2 font-weight-medium ml-2" v-html="formatMoney({amount: item.unitAmount * item.quantity, currency: item.currency})"></span>
            </div>

            <!-- Row 2: Price Variant Name and Unit Price -->
            <div class="d-flex justify-space-between align-center mt-0">
              <span class="text-caption text-truncate price-variant">{{ item.priceName || 'Standard' }}</span>
              <span class="text-caption ml-2 text-grey-lighten-1" v-html="formatMoney({amount: item.unitAmount, currency: item.currency})"></span>
            </div>
            
            <!-- Row 3: Inventory Status and Quantity Controls -->
            <div class="d-flex justify-space-between align-center mt-1">
              <!-- Inventory Status -->
              <div class="inventory-status">
                <v-chip
                  v-if="item.hasInventory !== false && item.stockCount === 0"
                  size="x-small"
                  color="error"
                  variant="tonal"
                >Out of Stock</v-chip>
                <v-chip
                  v-else-if="item.hasInventory !== false && item.quantity > (item.stockCount || 0)"
                  size="x-small"
                  color="error"
                  variant="tonal"
                >Exceeds ({{ item.stockCount }})</v-chip>
                <v-chip
                  v-else-if="item.hasInventory !== false && item.isLowStock"
                  size="x-small"
                  color="warning"
                  variant="tonal"
                >Only {{ item.stockCount }} left</v-chip>
                <v-chip
                  v-else-if="item.hasInventory !== false"
                  size="x-small"
                  color="success"
                  variant="tonal"
                >{{ item.stockCount }} available</v-chip>
                <v-chip
                  v-else
                  size="x-small"
                  color="info"
                  variant="tonal"
                >Unlimited</v-chip>
              </div>
              
              <!-- Quantity Controls -->
              <div class="d-flex align-center quantity-controls">
                <v-btn
                  icon="mdi-minus"
                  density="compact"
                  size="small"
                  variant="text"
                  :disabled="item.quantity === 1"
                  @click="decreaseQuantity(item.id)"
                  class="quantity-btn"
                />
                <span
                  :class="{'text-error': item.hasInventory !== false && item.quantity > (item.stockCount || 0)}"
                  class="mx-2 text-body-2 font-weight-medium"
                >{{ item.quantity }}</span>
                <v-btn
                  icon="mdi-plus"
                  density="compact"
                  size="small"
                  variant="text"
                  :disabled="item.hasInventory !== false && (item.quantity >= (item.stockCount || 0) || (item.stockCount || 0) === 0)"
                  @click="increaseQuantity(item.id)"
                  class="quantity-btn"
                />
              </div>
            </div>
          </div>
        </v-list-item>
      </transition-group>
      
      <v-divider class="mt-2 mb-2"></v-divider>
      
      <!-- Checkout Warning Message -->
      <v-alert
        v-if="hasInventoryIssues"
        type="error"
        variant="tonal"
        density="compact"
        class="mx-4 mb-2"
      >
        {{ inventoryIssueMessage }}
      </v-alert>
      
      <!-- Checkout Section -->
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <span class="text-h6">{{ $t("common.action.total") }}</span>
          <span class="text-h6" v-html="formatMoney({amount: cartTotal, currency: cartItems.length > 0 ? cartItems[0].currency : 'usd'})"></span>
        </div>
        
        <v-btn
          prepend-icon="mdi-shield-lock"
          block
          size="large"
          color="blue"
          variant="flat"
          class="mb-2"
          @click="checkout"
          :loading="cartStore.loading"
          :disabled="cartStore.loading || hasInventoryIssues"
        >
          {{ $t("common.cart.checkout") }}
        </v-btn>
      </div>
    </v-list>

    <div v-else class="d-flex flex-column align-center justify-center fill-height">
      <v-icon icon="mdi-cart-outline" size="64" color="grey-lighten-1" class="mb-4"></v-icon>
      <span class="text-subtitle-1 text-grey">{{ $t("common.cart.noItems") }}</span>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useCartStore } from "@/stores/cart";
import default_product_image from "@/assets/default_product_image.png";
import { formatMoney } from "@/services/util.service";

const cartStore = useCartStore();
const cartItems = computed(() => cartStore.items);
const cartTotal = computed(() => cartStore.total);

// Get product name from the store using getProductName getter
const getProductName = (productId: string) => {
  return cartStore.getProductName(productId);
};

// Get image from the item or from product map if available
const getItemImage = (item: any) => {

  
  // // Otherwise try to get it from the product map
  // const productImage = cartStore.getProductImage(item.productId);
  // if (productImage) {
  //   return productImage;
  // }
  
  // Fall back to default image
  return default_product_image;
};

// Check for inventory issues
const hasInventoryIssues = computed(() => {
  return cartItems.value.some(item => 
    item.hasInventory !== false && (item.stockCount === 0 || item.quantity > (item.stockCount || 0))
  );
});

// Generate an appropriate error message based on inventory issues
const inventoryIssueMessage = computed(() => {
  const outOfStockItems = cartItems.value.filter(item => 
    item.hasInventory !== false && item.stockCount === 0
  );
  const overLimitItems = cartItems.value.filter(item => 
    item.hasInventory !== false && (item.stockCount || 0) > 0 && item.quantity > (item.stockCount || 0)
  );
  
  if (outOfStockItems.length > 0) {
    return outOfStockItems.length === 1 
      ? `"${getProductName(outOfStockItems[0].productId)}" is out of stock.`
      : `${outOfStockItems.length} items in your cart are out of stock.`;
  }
  
  if (overLimitItems.length > 0) {
    return overLimitItems.length === 1
      ? `Requested quantity for "${getProductName(overLimitItems[0].productId)}" exceeds available stock.`
      : `Requested quantities for ${overLimitItems.length} items exceed available stock.`;
  }
  
  return "Inventory issues prevent checkout.";
});

const increaseQuantity = (itemId: string) => {
  const item = cartItems.value.find(item => item.id === itemId);
  if (item) {
    if (item.hasInventory === false || item.quantity < (item.stockCount || 0)) {
      cartStore.updateItemQuantity(itemId, 1);
    }
  }
};

const decreaseQuantity = (itemId: string) => {
  cartStore.updateItemQuantity(itemId, -1);
};

const checkout = async () => {
  if (!hasInventoryIssues.value) {
    await useCartStore().checkoutCart();
  }
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

/* Hide scrollbar for clean look */
:deep(.v-navigation-drawer__content::-webkit-scrollbar) {
  width: 6px;
}

:deep(.v-navigation-drawer__content::-webkit-scrollbar-thumb) {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

/* Cart item spacing adjustments */
.cart-list :deep(.v-list-item__content) {
  padding: 0;
}

.cart-item-content {
  width: 100%;
  padding: 0 4px;
}

.product-name {
  max-width: 70%;
}

.price-variant {
  max-width: 70%;
}

.inventory-status {
  min-width: 80px;
}

.quantity-controls {
  min-width: 100px;
  justify-content: flex-end;
}

.quantity-btn {
  min-width: 32px;
  min-height: 32px;
}

.remove-btn {
  opacity: 0.7;
  margin-right: 6px;
}

.remove-btn:hover {
  opacity: 1;
}
</style>
