import { AxiosResponse } from "axios";

export const isSuccessfulResponse = (response: AxiosResponse): boolean => {
  return response.status >= 200 && response.status < 400;
};

export const formatMoney = (data: any): string => {
  const {
    currency,
    amount = 0,
    hideZero = false,
    hideCurrency = false,
    hideAmount = false,
  } = data;
  // Amount is expected to be in "cents"; No decimals!!!
  // @todo support other currencies
  if (hideAmount) {
    return "—";
  }
  const roundedAmount = Math.round(amount);
  const dollars =
    roundedAmount < 0 ? (roundedAmount / 100) * -1 : roundedAmount / 100;
  // split integer and decimal
  const dollarsArray = dollars.toFixed(2).split(".");
  // separate integer with comma every 3 digits
  dollarsArray[0] = dollarsArray[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // join sign, integer and decimal
  const monies = "$" + dollarsArray.join(".");

  if (hideZero && roundedAmount === 0) {
    return "";
  }

  // Format the currency part with gray color
  const currencyPart = currency && !hideCurrency 
    ? `<span class="text-grey text-caption ms-1">${currency.toUpperCase()}</span>` 
    : "";

  const amountText = roundedAmount < 0 ? `(${monies})` : monies;
  
  return `${amountText}${currencyPart}`;
};

export const truncateString = (str: string, length: number) => {
  if (str?.length && str.length > length) {
    return str.substring(0, length) + "...";
  } else if (str) {
    return str;
  } else {
    return "-";
  }
};

export const parseRange = (inputString: string) => {
  if (!inputString) {
    return;
  }
  // Split the string by the pipe ('|') character
  const parts = inputString.split("|");

  // Extract the second and third elements as minimum and maximum
  const minimum = parseInt(parts[1], 10);
  const maximum = parseInt(parts[2], 10);

  // Return a string representation of the range
  return `between ${minimum} to ${maximum}`;
};
