export default {
  inventory: {
    stockWarning: "Avertissement de stock",
    noWarning: "Aucun avertissement",
    stockRangeDisabledForOutOfStock: "La plage de stock est désactivée pour les articles en rupture de stock"
  },
  common: {
    action: {
      priceLowStockThresholdDesc: "Le seuil auquel cette option de prix est signalée comme étant en stock bas. Lorsque l'inventaire tombe en dessous de ce nombre, une alerte de stock bas sera déclenchée.",
      priceStartingStockAmountDesc: "La quantité initiale d'inventaire pour cette option de prix. Cela définit le niveau de stock initial pour la variante du produit.",
      sku: "SKU",
      loading: "Chargement..."
    },
    filter: {
      apply: "Appliquer le filtre",
      clear: "Effacer le filtre",
      clearAll: "Effacer tous les filtres",
      byProduct: "Filtrer par produit",
    },
    search: {
      products: "Rechercher des produits",
      noProductsFound: "Aucun produit trouvé",
    },
  },
  welcome: "Bienvenue"
};
